import { useEffect, useReducer } from 'react';
import LoadedItemList from './LoadedItemList';
import { Flex } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { ComboboxItem } from './types';
import {
  GetItemPropsOptions,
  GetItemPropsReturnValue,
  GetMenuPropsOptions,
  GetMenuPropsReturnValue,
  GetPropsCommonOptions,
  Overwrite,
  PropGetters,
} from 'downshift8';
import { ComboboxProps, ItemsLoader, Size } from './Combobox';

export const ITEMS_PER_LIST = 15;

const initialItemListState = {
  loading: true,
  loadingMore: false,
  loadedItems: [],
  currentPage: 1,
  pageNumber: 1,
};

interface LoadingItemsAction {
  type: typeof LOADING_ITEMS;
}

interface ItemsLoadedAction {
  type: typeof ITEMS_LOADED;
  loadedItems: ComboboxItem[];
  pageNumber: number;
}

interface LoadingMoreItemsAction {
  type: typeof LOADING_MORE_ITEMS;
  pageNumber: number;
}

interface LoadedMoreItemsAction {
  type: typeof LOADED_MORE_ITEMS;
  loadedItems: ComboboxItem[];
}

interface ItemListState {
  loading: boolean;
  loadingMore: boolean;
  loadedItems: ComboboxItem[];
  currentPage: number;
  pageNumber: number;
}

const LOADING_ITEMS = 'LOADING_ITEMS';
const ITEMS_LOADED = 'ITEMS_LOADED';
const LOADING_MORE_ITEMS = 'LOADING_MORE_ITEMS';
const LOADED_MORE_ITEMS = 'LOADED_MORE_ITEMS';

type NewType =
  | LoadingItemsAction
  | ItemsLoadedAction
  | LoadingMoreItemsAction
  | LoadedMoreItemsAction;

function itemListReducer(state: ItemListState, action: NewType): ItemListState {
  switch (action.type) {
    case LOADING_ITEMS:
      return { ...initialItemListState };
    case ITEMS_LOADED:
      return {
        loadingMore: false,
        loading: false,
        loadedItems: action.loadedItems,
        currentPage: 1,
        pageNumber: action.pageNumber,
      };
    case LOADING_MORE_ITEMS:
      return {
        ...state,
        loadingMore: true,
        currentPage: action.pageNumber,
      };
    case LOADED_MORE_ITEMS:
      return {
        ...state,
        loadingMore: false,
        loadedItems: [...state.loadedItems, ...action.loadedItems],
      };
  }

  return state;
}

export default function DownShiftItemList({
  getItemProps,
  highlightedIndex,
  inputValue,
  getMenuProps,
  loadSelectItems,
  size,
  selectedItem,
  accentColor,
}: {
  getItemProps: PropGetters<ComboboxItem>['getItemProps'];
  highlightedIndex: number | null;
  inputValue: string | null;
  getMenuProps: PropGetters<ComboboxItem>['getMenuProps'];
  loadSelectItems: ItemsLoader;
  size: Size;
  selectedItem: ComboboxItem | undefined;
  accentColor?: 'tournesol' | 'menthe' | 'tuile' | 'brand';
}) {
  const [state, dispatch] = useReducer(itemListReducer, initialItemListState);

  useEffect(() => {
    if (state.currentPage === 1) {
      return;
    }

    let abortController: AbortController | null = null;
    if (typeof AbortController !== 'undefined') {
      abortController = new AbortController();
    }

    async function loadMoreSelectItems() {
      const result = await loadSelectItems(
        abortController,
        inputValue,
        state.currentPage
      );
      if (!result) {
        return;
      }

      const [loadedItems] = result;
      dispatch({
        type: LOADED_MORE_ITEMS,
        loadedItems,
      });
    }

    loadMoreSelectItems();

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [state.currentPage, inputValue, loadSelectItems]);

  useEffect(() => {
    let abortController: AbortController | null = null;
    if (typeof AbortController !== 'undefined') {
      abortController = new AbortController();
    }

    async function loadSelectItemsFirstPage() {
      const result = await loadSelectItems(abortController, inputValue, 1);

      if (!result) {
        return;
      }

      if (abortController?.signal?.aborted) {
        return;
      }

      const [loadedItems, resultNumber] = result;

      dispatch({
        type: ITEMS_LOADED,
        loadedItems,
        pageNumber: Math.ceil(resultNumber / ITEMS_PER_LIST),
      });
    }

    dispatch({ type: LOADING_ITEMS });
    loadSelectItemsFirstPage();

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [inputValue, loadSelectItems]);

  if (state.loading) {
    const computeLoadingFontSize = () => {
      if (size === 'xs') {
        return '.75rem';
      }
      if (size === 'sm') {
        return '.875rem';
      }
      return '1em';
    };
    return (
      <Flex
        color="black"
        py={1}
        px={2}
        position="absolute"
        background="white"
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        zIndex="dropdown"
        backgroundColor="white"
        fontSize={computeLoadingFontSize()}
      >
        <span>Chargement en cours</span>
        <Spinner size="xs" />
      </Flex>
    );
  }

  return (
    <LoadedItemList
      getMenuProps={getMenuProps}
      getItemProps={getItemProps}
      loadedItems={state.loadedItems}
      highlightedIndex={highlightedIndex}
      hasMoreItems={
        state.pageNumber !== 0 && state.currentPage !== state.pageNumber
      }
      currentPage={state.currentPage}
      loadingMore={state.loadingMore}
      loadNextItems={(pageNumber: number) => {
        dispatch({ type: LOADING_MORE_ITEMS, pageNumber });
      }}
      size={size}
      selectedItem={selectedItem}
      accentColor={accentColor}
    />
  );
}

import { Flex, List, ListItem } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { ComboboxItem } from './types';
import {
  GetItemPropsOptions,
  GetItemPropsReturnValue,
  GetMenuPropsOptions,
  GetMenuPropsReturnValue,
  PropGetters,
} from 'downshift8';
import { Size } from './Combobox';

export default function LoadedItemList({
  getMenuProps,
  loadedItems,
  getItemProps,
  highlightedIndex,
  hasMoreItems,
  loadNextItems,
  currentPage,
  loadingMore,
  size,
  selectedItem,
  accentColor,
}: {
  getMenuProps: PropGetters<ComboboxItem>['getMenuProps'];
  loadedItems: ComboboxItem[];
  getItemProps: PropGetters<ComboboxItem>['getItemProps'];
  highlightedIndex: number | null;
  hasMoreItems: boolean;
  loadNextItems: (pageNumber: number) => void;
  currentPage: number;
  loadingMore: boolean;
  size: Size;
  selectedItem?: ComboboxItem;
  accentColor?: 'tournesol' | 'menthe' | 'tuile' | 'brand';
}) {
  const menuRef = useRef<HTMLElement | null>();

  const menuProps = getMenuProps({
    ref: e => {
      menuRef.current = e;
    },
  });

  useLayoutEffect(() => {
    if (!loadingMore || !menuRef.current) {
      return;
    }

    menuRef.current.scrollTop =
      menuRef.current.scrollHeight - menuRef.current.offsetHeight;
  }, [loadingMore]);

  useEffect(() => {
    const menuElement = menuRef.current;

    if (!menuElement || !hasMoreItems || loadingMore) {
      return;
    }
    const scrollListener = () => {
      if (
        menuElement.scrollTop >=
        menuElement.scrollHeight - menuElement.offsetHeight
      ) {
        loadNextItems(currentPage + 1);
      }
    };

    menuElement.addEventListener('scroll', scrollListener);

    return () => {
      if (!menuElement) {
        return;
      }

      menuElement.removeEventListener('scroll', scrollListener);
    };
  }, [hasMoreItems, loadingMore, currentPage, loadNextItems]);

  let noResult = null;

  if (loadedItems.length === 0) {
    noResult = (
      <ListItem
        {...menuProps}
        bg="white"
        key={'no-result'}
        py={1}
        px={2}
        cursor="default"
        display="flex"
        justifyContent="space-between"
      >
        <span>Aucun résultat</span>
      </ListItem>
    );
  }

  const computeFontSize = () => {
    if (size === 'xs') {
      return '.75rem';
    }
    if (size === 'sm') {
      return '.875rem';
    }
    return '1em';
  };

  const handleListItemBackgroundColor = accentColor => {
    if (!accentColor) return 'brand.100';
    else return accentColor + '.100';
  };

  const handleListItemColor = accentColor => {
    if (!accentColor) return 'white';
    else return 'black';
  };

  return (
    <List
      {...menuProps}
      maxHeight="10em"
      overflowY="auto"
      bg="white"
      borderWidth="1px"
      borderColor="black.25"
      borderRadius="xl"
      width="100%"
      zIndex="dropdown"
      padding="0.35rem"
      fontSize={computeFontSize()}
    >
      {loadedItems.map((loadedItem: ComboboxItem, index: number) => {
        let bg = 'transparent';
        let color = null;
        if (
          selectedItem &&
          selectedItem.id &&
          loadedItem?.id === selectedItem?.id
        ) {
          bg = accentColor ? accentColor + '.100' : 'brand.100';
          color = 'white';
        }
        if (highlightedIndex === index) {
          bg = accentColor ? accentColor + '.100' : 'brand.100';
          color = 'white';
        }

        return (
          <ListItem
            {...getItemProps({
              item: loadedItem,
              index,
            })}
            key={loadedItem.id}
            backgroundColor={
              highlightedIndex === index
                ? handleListItemBackgroundColor(accentColor)
                : 'white'
            }
            color={
              highlightedIndex === index
                ? handleListItemColor(accentColor)
                : 'black.50'
            }
            borderRadius="lg"
            padding="0.5rem"
            cursor="pointer"
          >
            {loadedItem.label}
          </ListItem>
        );
      })}

      {noResult}

      {loadingMore && (
        <Flex
          color="black"
          py={1}
          px={2}
          position="absolute"
          w="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <span>Chargement en cours</span>
          <Spinner size="xs" />
        </Flex>
      )}
    </List>
  );
}
